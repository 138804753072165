@import '../../styles/variables.scss';

.header-nav {
  position: fixed;
  z-index: 35;
  top: $spacing-sm;
  left: $spacing-m;
  text-align: left;
  margin: auto;

  ul.nav-icon {
    padding: 0;
    margin: 0;
    cursor: pointer;

    svg {
      width: $spacing-l;
      height: $spacing-l;
      fill: $link-color-selected;
      transition: fill $transition-timing ease-in-out;
    }

    &:hover {
      svg {
        fill: $custom-white;
      }
    }
  }

  ul.nav-icon li:hover ul.nav-links {
    list-style: square;
    color: $link-color-selected;
    display: block;
    transition: display $transition-timing ease-in-out;
  }

  ul.nav-links {
    position: absolute;
    top: $spacing-mml;
    left: 0px;
    z-index: 40;
    display: none;
    padding: 0px;
    margin: $spacing-s 0;
  }
  
  ul.nav-links li {
    cursor: poiner;
    margin-left: 20px;
    padding: 0px 0px $spacing-m 0px;
    font-size: $font-m;
    width: 50vw;

  }
  
  .nav-links li a {
    text-decoration: none;
    color: $custom-white;
    border-bottom: 0px solid $link-color-selected;
    transition: color $transition-timing ease-in-out;
    transition: border-bottom $transition-timing ease-in-out;    
  }
  
  .nav-links li a:hover {
    border-bottom: 2px solid $link-color-selected;
    color: $link-color-selected;
  }
}


@media only screen and (min-width: $bp-mobile-max) {
  .header-nav {
    ul.nav-icon {


      svg {
        width: $spacing-xl;
        height: $spacing-xl;
        fill: $link-color-selected;
      }
    }

    .nav-links {
      display: none;
      padding: 0px;
      margin: $spacing-xl 0;
    }
  }
}

