$link-color-selected: #ff0000; 
$custom-black: #000000;   
$custom-white: #ffffff;
$custom-grey: grey;
$custom-light-grey: lightgray;
$success: lightgreen;
$error: lightcoral;
$warning: orange;

$spacing-xs: 5px;
$spacing-s: 10px;
$spacing-sm: 15px;
$spacing-m: 20px;
$spacing-ml: 30px;
$spacing-mml: 35px; 
$spacing-l: 40px; 
$spacing-xl: 50px;
$spacing-xxl: 60px;
$spacing-xxxl: 80px;

$font-xs: 14px;
$font-s: 16px;
$font-m: 18px;
$font-l: 24px;
$font-xl: 28px;
$font-xxl: 32px;
$font-xxxl: 36px;

$bp-mobile-max: 768px;


$font-weight-xs: 100;
$font-weight-s: 200;
$font-weight-sm: 300;
$font-weight-m: 400;
$font-weight-ml: 500;
$font-weight-l: 500;
$font-weight-xl: 600;
$font-weight-xxl: 700;
$font-weight-xxxl: 800;

$transition-timing: 0.45s;
